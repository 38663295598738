import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate, render } from 'react-dom';

import App from './components/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollTop from './components/Real/ScrollTop';

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  hydrate((
    <BrowserRouter>
      <ScrollTop/>
      <App />
    </BrowserRouter>
  ), rootElement)
} else {
  render((
    <BrowserRouter>
      <ScrollTop/>
      <App />
    </BrowserRouter>
  ), rootElement)
}
