export default {
  maxWidth: '1120px',
  bgColor: '#efefef',
  blackColor: '#16181D',
  darkGreyColor: '#313131',
  buttonColor: '#899EC7',
  textWhiteColor: '#F7F7F7',
  textGrayColor: '#969696',
  textBlackColor: '#444444',
  textBlueColor: '#727781',
  textRedColor: '#bb2231',
  boxBorder: '1px solid #c3c3c3',
  footerBgColor: '#1A1A1A',
  footerTextColor: '#7a7b80',
};
